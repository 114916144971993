import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

const Svg = styled.svg`
  display: flex;
  align-items: center;
  width: ${props => props.width};
  min-width: ${props => props.width};
  height: ${props => props.height};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  g {
    stroke: ${props =>
      props.checked ? props.theme.colors.white : props.theme.colors.grey900};
    opacity: ${props => (props.disabled ? '0.5' : '1')};
  }
  ${props => {
    if (props.hasBorder) {
      return `border: 1px solid ${props.theme.colors.grey900}; border-radius: 15%;`
    }
  }}

  &:focus {
    outline: 1px solid ${props => props.theme.colors.primary};
  }
`

const CheckBoxIcon = props => {
  const { title, color, theme, dataTestId, dataId, ...remaining } = props
  const state = props.checked ? 'on' : 'off'
  const iconTitle = title ? `${title} is ${state}.` : ''
  const iconColor = theme.colors[color] || theme.colors.grey700

  return (
    <Svg
      tabIndex={0}
      role="checkbox"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 15"
      title={iconTitle}
      data-testid={dataTestId}
      data-id={dataId}
      {...remaining}>
      {props.checked ? (
        <g className="checkbox" fill="none" fillRule="evenodd">
          <rect width="15" height="15" fill={iconColor} rx="3" />
          <path
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M3 7.466L6.023 10.5l5.98-6"
          />
        </g>
      ) : props.partial ? (
        <g className="checkbox" fill="none" fillRule="evenodd">
          <rect width="15" height="15" fill={iconColor} rx="3" />
          <path
            fillRule="nonzero"
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M3.5 7.5h8"
          />
        </g>
      ) : (
        <rect
          className="rectangle"
          width="14"
          height="14"
          x=".5"
          y=".5"
          fill="#FFF"
          fillRule="evenodd"
          stroke="#444"
          rx="3"
        />
      )}
    </Svg>
  )
}

CheckBoxIcon.defaultProps = {
  disabled: false,
  width: '15px',
  height: '15px',
  dataTestId: ''
}

CheckBoxIcon.propTypes = {
  checked: PropTypes.bool,
  partial: PropTypes.bool,
  hasBorder: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  dataTestId: PropTypes.string,
  dataId: PropTypes.string
}

const wrappedComponent = withTheme(CheckBoxIcon)

export { wrappedComponent as CheckBoxIcon }
