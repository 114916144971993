import React from 'react'
import PropTypes from 'prop-types'

const ExclamationIcon = ({ size, className }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 12 12" className={className}>
      <g fill="none" fillRule="evenodd">
        <circle cx="6" cy="6" r="6" fill="#8299A4" />
        <path
          fill="#FFF"
          d="M6 10c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8-.442 0-.8.358-.8.8 0 .442.358.8.8.8z"
        />
        <rect
          width="1.55"
          height="4.75"
          x="5.225"
          y="2.825"
          fill="#FFF"
          stroke="#8299A4"
          strokeWidth=".05"
          rx=".775"
        />
      </g>
    </svg>
  )
}

ExclamationIcon.defaultProps = {
  size: '12px',
  className: undefined
}

ExclamationIcon.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string
}

export { ExclamationIcon }
