import React from 'react'
import styled from 'styled-components'
import { COLORS } from 'COLORS'

const IconWrapper = styled.svg`
  width: ${props => props.width || '11px'};
  height: ${props => props.height || '17px'};
`

const DecreaseValueArrow = props => {
  const fillColor = props.color || COLORS.red500
  return (
    <IconWrapper {...{ ...props, viewBox: '0 0 11 17' }}>
      <path
        fill={fillColor}
        fillRule="nonzero"
        d="M5.5 0c-.863 0-1.46.564-1.46 1.46v10.626l-1.593-1.594a1.421 1.421 0 0 0-2.024 0 1.424 1.424 0 0 0 0 2.026l4.048 4.05c.299.3.73.432 1.029.432.431 0 .73-.133 1.029-.432l4.048-4.05a1.424 1.424 0 0 0 0-2.026 1.421 1.421 0 0 0-2.024 0L6.96 12.22V1.46C6.96.73 6.23 0 5.5 0z"
      />
    </IconWrapper>
  )
}

export { DecreaseValueArrow }
