import React from 'react'
import styled from 'styled-components'
import { COLORS } from 'COLORS'

const IconWrapper = styled.svg`
  width: ${props => props.width || '11px'};
  height: ${props => props.height || '17px'};
`

const IncreaseValueArrow = props => {
  const fillColor = props.color || COLORS.green700
  return (
    <IconWrapper {...{ ...props, viewBox: '0 0 11 17' }}>
      <path
        fill={fillColor}
        fillRule="nonzero"
        d="M5.5 17c-.863 0-1.46-.564-1.46-1.46V4.913L2.447 6.508a1.421 1.421 0 0 1-2.024 0 1.424 1.424 0 0 1 0-2.026L4.471.432C4.77.132 5.201 0 5.5 0c.431 0 .73.133 1.029.432l4.048 4.05c.564.565.564 1.461 0 2.026a1.421 1.421 0 0 1-2.024 0L6.96 4.78V15.54C6.96 16.27 6.23 17 5.5 17z"
      />
    </IconWrapper>
  )
}

export { IncreaseValueArrow }
