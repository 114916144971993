import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Svg = styled.svg`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  .close-icon,
  #Close-Button {
    // Being a legacy component, it uses the string name of the color in src/js/constants/colors.js,
    // and not the value itself
    stroke: ${props => props.theme.colors[props.color]};
    fill: ${props => props.theme.colors[props.color]};
    stroke-width: ${props => props.strokeWidth};
  }
`

export const CloseIcon = props => {
  const { className, dataTestId } = props
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      className={className}
      data-testid={dataTestId}
      {...props}>
      <title>Close</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          className="close-icon"
          transform="translate(-962.000000, -419.000000)"
          strokeWidth="0.5"
          fillRule="nonzero">
          <g transform="translate(211.000000, 405.000000)">
            <g id="Close-Icon" transform="translate(752.000000, 15.000000)">
              <path
                d="M11.147541,0.147540984 L6.3442623,4.95081967 C6.14754098,5.14754098 5.83606557,5.14754098 5.6557377,4.95081967 L0.852459016,0.147540984 C0.655737705,-0.0491803279 0.344262295,-0.0491803279 0.163934426,0.147540984 L0.163934426,0.147540984 C-0.0327868852,0.344262295 -0.0327868852,0.655737705 0.163934426,0.836065574 L4.96721311,5.63934426 C5.16393443,5.83606557 5.16393443,6.14754098 4.96721311,6.32786885 L0.147540984,11.147541 C-0.0491803279,11.3442623 -0.0491803279,11.6557377 0.147540984,11.8360656 L0.147540984,11.8360656 C0.344262295,12.0327869 0.655737705,12.0327869 0.836065574,11.8360656 L5.63934426,7.03278689 C5.83606557,6.83606557 6.14754098,6.83606557 6.32786885,7.03278689 L11.147541,11.852459 C11.3442623,12.0491803 11.6557377,12.0491803 11.8360656,11.852459 L11.8360656,11.852459 C12.0327869,11.6557377 12.0327869,11.3442623 11.8360656,11.1639344 L7.04918033,6.3442623 C6.85245902,6.14754098 6.85245902,5.83606557 7.04918033,5.6557377 L11.852459,0.852459016 C12.0491803,0.655737705 12.0491803,0.344262295 11.852459,0.163934426 L11.852459,0.163934426 C11.6557377,-0.0327868852 11.3442623,-0.0327868852 11.147541,0.147540984 Z"
                id="Close-Button"
              />
            </g>
          </g>
        </g>
      </g>
    </Svg>
  )
}

CloseIcon.defaultProps = {
  className: 'close',
  color: 'grey300',
  size: 12,
  strokeWidth: 0.5,
  dataTestId: 'close'
}

CloseIcon.propTypes = {
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  size: PropTypes.number,
  className: PropTypes.string,
  dataTestId: PropTypes.string
}
