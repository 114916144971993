import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { COLORS } from 'COLORS'

const ORIENTATION = {
  left: '-180',
  right: '0',
  up: '-90',
  down: '90'
}

const IconWrapper = styled.svg`
  width: ${props => props.width};
  min-width: ${props => props.width};
  height: ${props => props.height};
  opacity: ${props => props.opacity};
  path {
    fill: ${props => COLORS[props.color]};
  }
  transform: rotate(${props => ORIENTATION[props.direction]}deg);

  ${props =>
    props.transition &&
    `
      transition: ${props.transition};
    `}
`

const CollapseIcon = ({
  width,
  height,
  className,
  color,
  title,
  direction,
  opacity,
  transition,
  onClick
}) => {
  return (
    <IconWrapper
      width={width}
      height={height}
      className={className}
      color={color}
      direction={direction}
      opacity={opacity}
      title={title}
      transition={transition}
      viewBox="0 0 12 18"
      onClick={onClick}>
      <path
        fill={COLORS.grey400}
        fillRule="evenodd"
        d="M11.593 8.161L2.38.348C1.835-.116.954-.116.41.348c-.547.461-.547 1.209 0 1.672L8.635 9 .41 15.979c-.547.462-.547 1.212 0 1.673.544.464 1.425.464 1.971 0l9.212-7.816c.27-.232.407-.534.407-.837 0-.303-.137-.606-.407-.838z"
      />
    </IconWrapper>
  )
}

CollapseIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  direction: PropTypes.oneOf(['left', 'right', 'up', 'down']),
  transition: PropTypes.string,
  opacity: PropTypes.number,
  onClick: PropTypes.func
}

CollapseIcon.defaultProps = {
  direction: 'left',
  className: undefined,
  color: 'grey400',
  width: '12px',
  height: '18px',
  title: '',
  transition: '',
  opacity: 1,
  onClick: () => {}
}

export { CollapseIcon as CollapseIconV2 }
