import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const InfoIconWrapper = styled.svg`
  width: 7px;
  height: 11px;
`

const InfoIcon = ({ color }) => {
  return (
    <InfoIconWrapper viewBox="0 0 7 11">
      <g fill="none" fillRule="evenodd">
        <path
          stroke={color}
          strokeLinecap="square"
          strokeWidth=".5"
          d="M1 10.25h5"
        />
        <path
          fill={color}
          fillRule="nonzero"
          d="M3.01 1c.079-.555.581-1 1.13-1 .552 0 .927.445.85 1-.08.555-.582 1-1.131 1-.55 0-.927-.445-.849-1zM4.974 8.288c-.32.53-.962.712-1.479.712-.836 0-1.255-.418-1.144-1.186l.599-4.326a.181.181 0 0 0-.18-.208h-.655c-.084 0-.126-.056-.113-.14 0-.084.071-.14.154-.14h2.26c.084 0 .14.056.125.14l-.682 4.883c-.057.432.056.559.334.559.251 0 .474-.196.6-.391.04-.056.099-.084.168-.056.042.028.056.097.013.153z"
        />
      </g>
    </InfoIconWrapper>
  )
}

InfoIcon.defaultProps = {
  color: '#C9BCBC'
}

InfoIcon.propTypes = {
  color: PropTypes.string
}

export { InfoIcon }
