import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLORS } from 'COLORS'

const IconWrapper = styled.svg`
  width: ${props => props.width};
  height: ${props => props.height};
  path {
    fill: ${props => COLORS[props.color]};
  }
`

const CollapseIcon = props => {
  const orientation = {
    left: '-90',
    right: '90',
    up: '0',
    down: '180'
  }

  return (
    <IconWrapper {...props} data-testid={props.dataTestId}>
      <g
        fill="none"
        fillRule="evenodd"
        transform={`rotate(${orientation[props.direction]} 11 10.5)`}>
        <path
          fill="#222"
          d="M10.47 0a.926.926 0 0 0-.634.288l-9.544 9.94a1.085 1.085 0 0 0-.023 1.456.908.908 0 0 0 1.352.027v-.002L10.5 2.462l8.88 9.247a.904.904 0 0 0 1.351-.025 1.085 1.085 0 0 0-.023-1.455L11.164.289A.927.927 0 0 0 10.47 0"
        />
      </g>
    </IconWrapper>
  )
}

CollapseIcon.defaultProps = {
  color: COLORS.grey900,
  width: '13px',
  height: '22px',
  direction: 'left',
  dataTestId: null
}

CollapseIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  direction: PropTypes.oneOf(['left', 'right', 'up', 'down']),
  dataTestId: PropTypes.string
}

export { CollapseIcon as CollapseIconV1 }
