import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLORS } from 'COLORS'

const IconWrapper = styled.svg`
  width: ${props => props.width};
  height: ${props => props.height};
`

const Chevron = props => {
  return (
    <IconWrapper {...props} viewBox="0 0 7 10">
      <path fill={COLORS.blue500} fillRule="evenodd" d="M7 5L0 0v10z" />
    </IconWrapper>
  )
}

Chevron.defaultProps = {
  width: '7px',
  height: '10px'
}

Chevron.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string
}

export { Chevron }
