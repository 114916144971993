import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TEXT_STYLES } from 'TEXT_STYLES'
import { withTranslation, Trans } from 'react-i18next'
import { sendCtaClickEvent } from 'helpers/segment'
import { CONTACT_SUPPORT } from 'constants/segment'
import { getPathData } from 'services/segment'
import { useSelector } from 'react-redux'
import { JSPRO_BUCKET } from 'constants/s3'
import { logException } from '../../config/sentry'

const Wrapper = styled.div`
  width: 100%;
  display: flex;

  .column {
    margin-top: ${props => (props.dynamicImport ? '111px' : '177px')};
    flex-direction: row;
    flex-basis: 50%;

    * {
      margin: 0 auto;
    }
  }

  h1 {
    ${TEXT_STYLES.Coaching1}
    text-align: left;
    max-width: 281px;
    margin-bottom: 20px !important;
  }

  p {
    ${TEXT_STYLES.BodyBlack}
    max-width: 281px;
  }

  a {
    ${TEXT_STYLES.H3Orange}
    text-decoration: underline;
    cursor: pointer;
  }

  .stack-trace {
    white-space: pre-wrap;
    text-align: left;
    width: max-content;
  }
`

const Logo = styled.img.attrs({
  src: `${JSPRO_BUCKET}/images/logos/js-logo-text.svg`,
  alt: 'jungle scout logo'
})`
  position: absolute;
  top: 20px;
  left: 20px;

  width: 164px;
  height: 25px;
  object-fit: contain;
`
const ErrorBoundaryContent = () => {
  const appType = useSelector(state => state.globalData.appType)
  const contactSupportCallback = () => {
    const page = window.location.hash.substr(1)
    const location = getPathData(page)?.name

    sendCtaClickEvent({
      destination: 'mail',
      text: CONTACT_SUPPORT,
      appType,
      location
    })
  }

  return (
    <p>
      <Trans i18nKey="Error.refreshOrConact">
        <a onClick={() => window.location.reload()}>Refresh</a> Or{' '}
        <a
          href="mailto:support@junglescout.com"
          onClick={contactSupportCallback}>
          Contact Support
        </a>
      </Trans>
    </p>
  )
}

const DEFAULT_STATE = { error: '', errorInfo: '', hasError: false }

// adapted from https://reactjs.org/docs/error-boundaries.html
export class ErrorBoundaryComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...DEFAULT_STATE,
      error: props.error || '',
      hasError: !!props.error
    }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  /**
   * this method only really works for dynamic imports but hopefully most of the time we hit this component it'll be at that level
   * What we're doing here is allowing the user to make use of the rest of the app if they nav to another page
   */
  componentDidUpdate(prevProps) {
    const { dynamicImport, history } = this.props
    // we don't get history from the root level error boundary so we have to check this
    // react docs say it's ok to call setState as long as call is wrapped in an if statement
    // https://reactjs.org/docs/react-component.html#componentdidupdate
    /* eslint-disable react/no-did-update-set-state */
    if (history?.location?.pathname !== prevProps.history?.location?.pathname) {
      this.setState({ ...DEFAULT_STATE })
    } else if (dynamicImport !== prevProps.dynamicImport) {
      this.setState({ ...DEFAULT_STATE })
    }
    /* eslint-enable react/no-did-update-set-state */
  }

  componentDidCatch(error, errorInfo) {
    const { history } = this.props
    // You can also log the error to an error reporting service
    logException(error, {
      extra: {
        pathname: history?.location?.pathname,
        stackTrace: errorInfo.componentStack
      }
    })

    this.setState({
      error,
      errorInfo
    })
  }

  renderStackTrace() {
    if (process.env.REACT_APP_JUNGLE_ENV === 'production') {
      return <img src={`${JSPRO_BUCKET}/images/error-page.png`} alt="error" />
    }

    const { error: stateError, errorInfo } = this.state
    const { error: propsError } = this.props
    const error = stateError || propsError

    // Note, we don't translate this because only devs see it locally (In reality should also never happen)
    if (!error) {
      return <p>Failed to get stack trace</p>
    }

    // again, it's not worth translating dev error messages here
    return (
      <>
        <h3>{error?.toString()}</h3>
        <div className="stack-trace">
          {(errorInfo ? errorInfo.componentStack?.trim() : error?.stack) ||
            'Stack trace unavailable'}
        </div>
        <br />
        <br />
        <i>This stack trace is not visible in production</i>
      </>
    )
  }

  renderAppDead() {
    const { t } = this.props
    return (
      <h1>
        {t('Error.appCrashed', 'Oops! Something seems to have gone wrong.')}
      </h1>
    )
  }

  renderPageDead() {
    const { t } = this.props
    return (
      <h1>
        {t(
          'Error.pageCrashed',
          'Oops! Something seems to be wrong with this page.'
        )}
      </h1>
    )
  }

  render() {
    const { hasError } = this.state
    const { dynamicImport, children, error } = this.props
    if (!hasError && !error) {
      return children
    }

    return (
      <>
        {!dynamicImport && <Logo />}
        <Wrapper dynamicImport={dynamicImport}>
          <div className="column">
            {dynamicImport ? this.renderPageDead() : this.renderAppDead()}
            <ErrorBoundaryContent />
          </div>
          <div className="column">{this.renderStackTrace()}</div>
        </Wrapper>
      </>
    )
  }
}

ErrorBoundaryComponent.defaultProps = {
  children: null,
  dynamicImport: null,
  error: null,
  history: null
}

/* eslint-disable react/forbid-prop-types */
ErrorBoundaryComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  dynamicImport: PropTypes.any,
  error: PropTypes.object,
  history: PropTypes.any,
  t: PropTypes.func.isRequired
}
/* eslint-enable react/forbid-prop-types */

export const ErrorBoundary = withTranslation()(ErrorBoundaryComponent)
