import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLORS } from 'COLORS'

const IconWrapper = styled.svg.attrs({
  viewBox: '0 0 25 25'
})`
  width: ${props => props.size};
  height: ${props => props.size};
`

export const EditIcon = props => {
  const color = COLORS[props.color] || props.color
  return (
    <IconWrapper {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(5 4)">
        <rect
          width="4.895"
          height="13.895"
          x="6.553"
          y=".553"
          stroke={color}
          strokeWidth="1.105"
          rx="1.105"
          transform="rotate(45 9 7.5)"
        />
        <path
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="1.105"
          d="M2.55 10.617L.5 16l5.372-2.078z"
        />
        <path fill={color} d="M10.82 4.965l.708.707-7.071 7.07-.707-.706z" />
        <path fill={color} d="M13.975 6.768l-.707.707-4.243-4.243.707-.707z" />
      </g>
    </IconWrapper>
  )
}

EditIcon.defaultProps = {
  color: COLORS.grey900,
  size: '25px',
  title: 'Edit'
}

EditIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string
}
