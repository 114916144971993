import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLORS } from 'COLORS'

const Svg = styled.svg`
  width: ${props => props.size};
  height: ${props => props.size};
  transition: 0.5s ease transform;

  ${props => props.isExpanded && 'transform: rotate(90deg);'}
`

const CollapseRowIcon = props => {
  const { id, isExpanded, size, className, color } = props
  const title = isExpanded ? 'Close' : 'Open'
  const titleId = `collapse-row-icon-${id || 'title'}`

  return (
    <Svg
      size={size}
      className={className}
      isExpanded={isExpanded}
      aria-labelledby={titleId}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 13">
      <title id={titleId}>{title}</title>
      <path
        fill={color}
        fillRule="evenodd"
        d="M7.5 6.717a.78.78 0 0 1-.237.558l-5.374 5.21a.829.829 0 0 1-1.15 0 .77.77 0 0 1 0-1.116l4.798-4.652L.739 2.063a.77.77 0 0 1 0-1.115.829.829 0 0 1 1.15 0l5.374 5.21a.78.78 0 0 1 .237.559"
      />
    </Svg>
  )
}

CollapseRowIcon.propTypes = {
  isExpanded: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  id: PropTypes.string
}

CollapseRowIcon.defaultProps = {
  size: '12px',
  color: COLORS.grey900,
  id: undefined
}

export { CollapseRowIcon }
