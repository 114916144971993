import React from 'react'
import { LoadingIcon } from '@junglescout/edna'

const LoadingPage = () => {
  return (
    <div className="loading-page">
      <div className="loading__wrapper">
        <LoadingIcon color="primary" size="50px" />
      </div>
    </div>
  )
}

export default LoadingPage
