import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const IconWrapper = styled.svg`
  width: ${props => props.size};
  height: ${props => props.size};
`

const CircleInfoIcon = props => {
  const { size, color } = props

  return (
    <IconWrapper size={size} viewBox="0 0 21 20">
      <circle cx="10.3335" cy="10" r="8" fill={color} />
      <circle cx="10.3335" cy="6" r="1" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.3335 8H11.3335V14H12.3335V15H9.3335V9H8.3335V8Z"
        fill="white"
      />
    </IconWrapper>
  )
}

CircleInfoIcon.defaultProps = {
  size: '21px',
  color: 'currentColor'
}

CircleInfoIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
}

export { CircleInfoIcon }
