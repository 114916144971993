import React from 'react'

export const ExpiringCreditCard = ({ title }) => {
  return (
    <svg
      title={title}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="230"
      height="115"
      viewBox="0 0 230 115">
      <defs>
        <filter
          id="prefix__a"
          width="117.1%"
          height="126.8%"
          x="-8.6%"
          y="-11%"
          filterUnits="objectBoundingBox">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="1"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy="2"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="3"
          />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
        </filter>
        <filter
          id="prefix__c"
          width="133.2%"
          height="132.8%"
          x="-16.6%"
          y="-13.4%"
          filterUnits="objectBoundingBox">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="1"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy="2"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="3"
          />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
        </filter>
        <rect
          id="prefix__b"
          width="128.281"
          height="82"
          x=".151"
          y=".54"
          rx="7"
        />
        <rect
          id="prefix__d"
          width="66.288"
          height="67.168"
          x="1.878"
          y=".558"
          rx="7"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#F3F5FF"
          d="M122.339.19c3.974 0 7.196 5.14 7.196 11.48 0 6.341-3.222 11.482-7.196 11.482h65.795c3.974 0 7.196 5.14 7.196 11.48 0 6.341-3.222 11.482-7.196 11.482h22.617c3.975 0 7.197 5.14 7.197 11.48 0 6.342-3.222 11.482-7.197 11.482h-19.533c-3.974 0-7.196 5.14-7.196 11.48 0 6.342 3.222 11.482 7.196 11.482h6.169c3.669 0 7.196 5.14 7.196 11.48 0 6.342-3.222 11.482-7.196 11.482h-53.46c-.529 0-1.045-.091-1.541-.264-.497.173-1.013.264-1.543.264H47.291c-3.975 0-7.197-5.14-7.197-11.481 0-6.34 3.222-11.481 7.197-11.481H7.196C3.222 92.038 0 86.898 0 80.557s3.222-11.481 7.196-11.481H48.32c3.974 0 7.196-5.14 7.196-11.481s-3.222-11.481-7.196-11.481H22.617c-3.974 0-7.196-5.14-7.196-11.481s3.222-11.481 7.196-11.481H63.74c-2.297 0-7.196-5.14-7.196-11.482 0-6.34 4.685-11.054 7.196-11.48 2.512-.427 54.625 0 58.6 0zm97.115 91.403c5.824 0 10.546 4.784 10.546 10.686 0 5.901-4.722 10.686-10.546 10.686-5.824 0-10.546-4.785-10.546-10.686 0-5.902 4.722-10.686 10.546-10.686z"
        />
        <g>
          <g transform="translate(31 19.248) translate(35.873 .752)">
            <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
            <use
              fill="#FFF"
              stroke="#C2C2C2"
              strokeWidth="2"
              xlinkHref="#prefix__b"
            />
            <g transform="translate(85 22.017)">
              <rect
                width="16.974"
                height="12"
                x=".546"
                y=".735"
                fill="#C2C2C2"
                rx="3"
              />
              <path
                stroke="#FFF"
                strokeLinejoin="round"
                d="M9.032 0L6.455 4.112 6.455 9.483 8.943 13.429"
              />
              <path
                stroke="#FFF"
                strokeLinejoin="round"
                d="M11.032 0L8.455 4.112 8.455 9.483 10.943 13.429"
                transform="matrix(-1 0 0 1 19.487 0)"
              />
              <path
                stroke="#FFF"
                d="M11.032 4.231L17.873 4.231M0 4.231L6.841 4.231M11.032 9.483L17.873 9.483M0 9.483L6.841 9.483"
              />
            </g>
            <g stroke="#7088F0" strokeLinecap="round" strokeWidth="1.5">
              <path
                d="M.035 6c.647.337.957.848.928 1.533C.935 8.22.626 8.708.035 9M2.07 4c1.294.786 1.913 1.978 1.857 3.578C3.87 9.177 3.25 10.318 2.07 11M4.14 2c2.2 1.235 3.242 3.109 3.129 5.622-.113 2.513-1.156 4.306-3.13 5.378"
                transform="translate(107 21)"
              />
              <path
                d="M7.14 0c2.589 1.684 3.827 4.24 3.713 7.666-.113 3.427-1.35 5.872-3.713 7.334"
                transform="translate(107 21)"
              />
            </g>
            <text
              fill="#C2C2C2"
              fontFamily="Inter"
              fontSize="9"
              fontWeight="500">
              <tspan x="20.077" y="54">
                0000 0000 0000 0000
              </tspan>
            </text>
            <text
              fill="#C2C2C2"
              fontFamily="Inter"
              fontSize="8"
              fontWeight="500">
              <tspan x="95.496" y="66">
                00/00
              </tspan>
            </text>
            <text
              fill="#C2C2C2"
              fontFamily="Inter"
              fontSize="8"
              fontWeight="500">
              <tspan x="8.864" y="66">
                Card Holder
              </tspan>
            </text>
          </g>
          <g transform="translate(31 19.248) translate(0 8.195)">
            <use fill="#000" filter="url(#prefix__c)" xlinkHref="#prefix__d" />
            <use
              fill="#FBFBFB"
              stroke="#C2C2C2"
              strokeWidth="2"
              xlinkHref="#prefix__d"
            />
            <path
              fill="#000"
              d="M1.507 1.536L1.507 1.517"
              transform="translate(22.148 37.69)"
            />
            <path
              fill="#C2C2C2"
              d="M0.873 14.805H68.16600000000001V16.332H0.873z"
            />
            <text
              fill="#4563EC"
              fontFamily="Inter"
              fontSize="36"
              fontWeight="500">
              <tspan x="12.161" y="52.115">
                :0!
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  )
}
